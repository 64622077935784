/* eslint-disable no-alert */
/* eslint-disable import/no-cycle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import graphReactor from '@/common/core/Graph/graphReactor';
import sleep from '@/utils/sleep';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import asyncForeach from '@/utils/async-foreach';
import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';
// import io from 'socket.io-client';

// const socket = io(`${process.env.VUE_APP_NOTIFICATION_SERVER}`);
// import root from vue from
// eslint-disable-next-line

export default {
  // Action to send information to Event Store
  async eventToEventStore({ getters }, {
    actor, verb, value, dataSource,
  }) {
    const actorToSend = actor || {
      ...getters['auth/authUser'],
    };
    const toSend = {
      actor: actorToSend,
      verb,
      value,
      dataSource,
    };
    toSend.dataSource = 'tj-client-web';

    await axios.post(`${process.env.VUE_APP_AXIOS_URI}/events/vs_store/update`, toSend, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    await axios.post(`${process.env.VUE_APP_ZELDA_URI}/event`, toSend);
  },

  TranscriptText({ commit }, payload) {
    commit('SET_TRANSCRIPT_DATA', payload);
  },

  audioText({ commit }, payload) {
    commit('SET_AUDIO_DATA', payload);
  },

  actionFeedback({ commit }, payload) {
    commit('NOTIFICATION_STORE', payload.verb);
  },

  async clearContext() {
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        await axios.delete(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/eddie`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  async EditorAIFetchProcess({ commit, dispatch }, payload) {
    const response = await dispatch('fetchFromOpenAI', payload);
    commit('EDITOR_AI_STORE', { payload, value: response });
  },

  /** Temporary Function: this needs to be replaced */
  async fetchFromOpenAI({ state }, payload) {
    let responseText = '';
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        let payloadData;
        if (typeof payload === 'string') {
          payloadData = {
            model: localStorage.getItem('currentModel') || 'text-davinci-003',
            maxWords: localStorage.getItem('currentModelMaxWord') || 300,
            prompt: payload,
          };
        } else {
          payloadData = payload;
        }

        const response = await fetch(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/eddie`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Add this line
          },
          body: JSON.stringify({
            ...payloadData,
            stream: true,
            ...(Boolean(state.gmodule.anchor) && { anchor: state.gmodule.anchor }),
          }),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        // The following function will process chunks as soon as they are available
        let chunk = await reader.read();
        while (!chunk.done) {
          const decodedChunk = decoder.decode(chunk.value);
          console.log(decodedChunk);
          responseText += decodedChunk;
          // eslint-disable-next-line no-await-in-loop
          chunk = await reader.read();
        }
      }
    } catch (err) {
      console.log(err);
    }
    return responseText;
  },

  async fetchArchiveBooks({ commit, state }, payload) {
    let archiveLibraryPayload;
    if (state.gmodule.anchor && state.gmodule.anchor.length > 0) {
      archiveLibraryPayload = `${payload} in ${state.gmodule.anchor}`;
    } else {
      archiveLibraryPayload = payload;
    }
    const response = await axios.get('https://archive.org/advancedsearch.php', {
      params: {
        q: archiveLibraryPayload,
        output: 'json',
        fl: ['identifier',
          'title',
          'creator',
          'date',
          'description',
          'subject',
          'publisher',
          'language',
          'type',
          'format',
          'source',
          'mediatype'],
        rows: '50',
        sort_order: 'asc',
        wt: 'json',
      },
    });
    this.archiveData = response.data.response.docs.filter((i) => i.mediatype === 'texts' && i.format.includes('Scandata'));
    commit('SET_ARCHIVE_DATA', this.archiveData);
  },

  async searchFromOpenAi({ commit }, payload) {
    commit('EMPTY_OPENAI_RESULT');
    commit('IS_SEARCHING_FROM_OPENAI', true);
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        let payloadData;
        if (typeof payload === 'string') {
          payloadData = {
            model: localStorage.getItem('currentModel') || 'text-davinci-003',
            maxWords: localStorage.getItem('currentModelMaxWord') || 300,
            prompt: payload,
          };
        } else {
          payloadData = payload;
        }

        const response = await fetch(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/eddie`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Add this line
          },
          body: JSON.stringify({
            ...payloadData,
            stream: true,
          }),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        // The following function will process chunks as soon as they are available
        let chunk = await reader.read();
        while (!chunk.done) {
          const decodedChunk = decoder.decode(chunk.value);
          console.log('xvf', decodedChunk);
          // payload.readData(decodedChunk);
          commit('SET_OPENAI_RESULT', { chunk: decodedChunk, prompt: payloadData.prompt });
          // eslint-disable-next-line no-await-in-loop
          chunk = await reader.read();
          console.log('xvf', 'searchFromOpenAI', chunk, payloadData);
        }
      }
    } catch (err) {
      commit('SET_OPENAI_RESULT', err);
      console.log(err);
    }
    commit('IS_SEARCHING_FROM_OPENAI', false);
  },

  toggleShowHistoryInOpenAI({ commit }) {
    commit('TOGGLE_SHOW_HISTORY_FROM_OPENAI');
  },

  async eddieHistory({ commit }, pageNo) {
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        const responseData = await axios.post(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/eddie/history`, {
          pageNo,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        commit('SET_EDDIE_HISTORY', responseData.data);
      }
    } catch (err) {
      console.log(err);
    }
  },

  async updateAnswer({ commit }, id) {
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        const responseData = await axios.post(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/eddie/history/${id}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit('UPDATE_ANSWER', responseData.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  },

  fetchBooks({ state, commit }) {
    return new Promise((resolve) => {
      axios
        .get(`${process.env.VUE_APP_AXIOS_URI}/book`)
        .then((res) => {
          commit('setBooks', res.data);
          resolve(state.books);
        })
        .catch((error) => {
          throw error;
        });
    });
  },

  fetchWidgetName({ commit }, value) {
    return new Promise((resolve) => {
      if (value) {
        commit('setWidgetName', value);
        resolve(value);
      } else {
        resolve(null);
      }
    });
  },

  async wikiWordFetch({ commit, state, dispatch }, wikiWord) {
    const loader = await dispatch('startLoader', {
      event: 'Wikipedia Lookup',
      message: wikiWord,
    });

    commit('setWikiWord', wikiWord);
    // First request without image
    const wikiInformation = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/wikidata`, {
      word: wikiWord,
      images: false,
      videos: false,
    });

    commit('setWikiInformation', wikiInformation.data);
    // second with images
    const wikiInformation2 = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/wikidata`, {
      word: wikiWord,
      images: true,
      videos: false,
    });

    commit('setWikiInformation', wikiInformation2.data);
    const currentDate = new Date().getTime();

    const action = {
      searchEntity: state.wikiWord,
      result: state.wikiInformation,
      timeStamp: {
        machineReadable: currentDate,
        humanReadable: moment(currentDate).format('MMMM Do YYYY, h:mm:ss a'),
      },
    };

    const relation = {
      verb: 'WIKI_LOOKUP',
    };

    const entity = {
      ...relation,
      value: action,
    };

    dispatch('eventToEventStore', entity);
    dispatch('endLoader', loader);
  },

  async dictWordPopUp({ dispatch }, dictPopUpWord) {
    const wikiInformation = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/dictionary-api`, {
      params: {
        word: dictPopUpWord,
      },
    });
    dispatch('eventToEventStore', {
      verb: 'QUICK_SEARCH',
      value: dictPopUpWord,
    });
    return wikiInformation.data;
  },

  async checkAndUpdateIfActiveGraphIsNotAvailable({ state, dispatch }, allGraph) {
    const graphDimension = allGraph ? allGraph[0].graph_dimension : null;
    let activeGraphId = null;
    if (graphDimension) {
      activeGraphId = state.active_graph_id[graphDimension];
    }
    if (activeGraphId) {
      const graphExist = allGraph.some((graphEl) => graphEl._id === activeGraphId);

      if (!graphExist) {
        const graphManifest = await dispatch('gmodule/getGraphManifest', activeGraphId);
        return [graphManifest, ...allGraph];
      }
    }
    return allGraph;
  },

  async eircSearch({ commit }, searchWord) {
    // First request without image
    const ericRecordResults = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore/eric`, {
      searchTerm: searchWord,
    });

    const { docs } = ericRecordResults.data;

    commit('setEricRecords', docs);
  },

  async fetchAllGraph({ commit, state, dispatch }, payload) {
    let responseData = null;
    if (payload.type === 'page') {
      responseData = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/g6graph`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        params: {
          pageNo: payload.pageNo,
          ...(state.library_mode_quest && { graph_dimension: 'quest_library' }),
          ...(payload.user === '@me' && { username: state.auth.user.username }),
        },
      });
    } else if (payload.type === 'search') {
      responseData = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/g6graph`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        params: {
          pageNo: payload.pageNo,
          searchWord: payload.searchWord,
        },
      });
    }

    // dispatch('eventToEventStore', {
    //   verb: 'GRAPH_LIBRARY',
    //   value: payload,
    // });
    const data = await dispatch('checkAndUpdateIfActiveGraphIsNotAvailable', responseData.data.data);
    commit('SET_ALL_GRAPH_LIST', { data });
  },

  async fetchGraphByTag({ commit }, tagId) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/g6graph/tag/${tagId}`);
    commit('SET_ALL_GRAPH_LIST', response.data);
  },

  async fetchExploreDescription({ commit }, exploreEntity) {
    const exploreResponseObj = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore/description`, {
      query: exploreEntity,
    });
    const descripiton = await exploreResponseObj.data.extract;

    commit('SET_EXPLORE_DESCRIPTION', descripiton);
  },

  // ** DETAIL - to fetch wikiDescription
  // eslint-disable-next-line no-empty-pattern
  async getDescription({ commit }, exploreEntity) {
    const getDescription = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore/description`, {
      query: exploreEntity,
    });
    const descripiton = await getDescription.data;
    commit('SET_DETAIL_DESCRIPTION', descripiton);
  },

  async fetchTagContent({ commit }, name) {
    const res = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/tag/${name}`);
    commit('SET_TAG_CONTENT', res.data);
  },

  // eslint-disable-next-line no-empty-pattern
  async fetchExploreInformation({ }, exploreEntity) {
    const exploreResponseObj = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore/links`, {
      query: exploreEntity,
    });
    return exploreResponseObj.data.links;
  },

  async graphDumpFetch({ commit, dispatch }) {
    const loader = await dispatch('startLoader', {
      event: 'Fetching Knowledge Graph',
      message: 'Neo4j',
    });
    const graphRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/query/json`);
    const forceGraph = graphRaw.data;

    commit('setVisitedGraph', forceGraph);
    dispatch('endLoader', loader);
  },
  setRangeValue({ commit }, rangeValue) {
    commit('setRangeValue', rangeValue);
  },
  setEdgeValue({ commit }, val) {
    commit('setEdgeValue', val);
  },
  setActiveLayoutKey({ commit }, layoutKey) {
    commit('setActiveLayoutKeyMut', layoutKey);
  },
  async activateLayout({ commit, state }, payload) {
    const latestLayout = state.layouts[state.layouts.view];
    if (latestLayout.layout_tabs[payload.layoutKey].includes(payload.componentName)) {
      const getIndexOfLayout = latestLayout.layout_tabs[payload.layoutKey].indexOf(payload.componentName);
      latestLayout.layout_tab_selection[payload.layoutKey] = getIndexOfLayout;
    } else {
      const changeLayouSelectionD = [...latestLayout.layout_tabs[payload.layoutKey], payload.componentName];
      latestLayout.layout_tabs[payload.layoutKey] = changeLayouSelectionD;
      const getIndexOfLayout = latestLayout.layout_tabs[payload.layoutKey].indexOf(payload.componentName);
      latestLayout.layout_tab_selection[payload.layoutKey] = getIndexOfLayout;
    }
    commit('SET_ACTIVATE_LAYOUT', latestLayout);
  },

  newEvent({ commit }, data) {
    commit('ADD_NEW_EVENT', data);
  },

  setNodeLabel({ commit }, nodeLabel) {
    commit('SET_NODE_LABEL', nodeLabel);
  },

  setNewNodeType({ commit }, newNodeType) {
    commit('SET_NODE_TYPE', newNodeType);
  },

  setEdgeLabel({ commit }, edgeLabel) {
    commit('SET_EDGE_LABEL', edgeLabel);
  },

  setEdgeStrength({ commit }, edgeStrength) {
    commit('SET_EDGE_STRENGTH', edgeStrength);
  },

  async graphVisitedDumpFetch({ commit, dispatch }) {
    const loader = await dispatch('startLoader', {
      event: 'Fetching Knowledge Graph',
      message: 'Neo4j',
    });
    const graphRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/query/json_mongo`);
    const forceGraph = graphRaw.data;

    commit('setVisitedGraph', forceGraph);
    dispatch('endLoader', loader);
  },

  fetchVerb({ commit }, verb) {
    commit('setVerb', verb);
    return new Promise((resolve) => {
      resolve(verb);
    });
  },

  toggleSidebar({ commit }) {
    commit('toggleSidebar');
  },

  updateGraphSetting({ commit }, filters) {
    commit('SET_FG_COMPONENT_SETTING', {
      nodeFilter: filters.nodeFilter,
      edgeFilter: filters.edgeFilter,
    });
  },

  setSelectedNode({ commit }, selectedNode) {
    commit('SET_SELECTED_NODE', selectedNode);
  },

  clearMetaSelection({ commit }) {
    commit('CLEAR_META_SELECTION');
  },

  async askWolfram({ commit, dispatch }, question) {
    const loader = await dispatch('startLoader', {
      event: 'Asking Wolfram Engine',
      message: question,
    });

    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/askwolfram`, {
      params: {
        question,
      },
    });
    const answer = response.data.ans;
    commit('ASK_WOLFRAM', {
      question,
      answer,
    });
    dispatch('endLoader', loader);

    dispatch('eventToEventStore', {
      verb: 'WOLFRAM_ALPHA',
      value: {
        question,
        answer,
      },
    });
  },

  showNotification({ commit }) {
    commit('SHOW_NOTIFICATION');
  },

  hideNotification({ commit }) {
    commit('HIDE_NOTIFICATION');
  },

  async mostSearchedWord({ state }, payload) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/search/count`, {
      params: {
        startDate: payload.date,
        endDate: payload.endDate,
        ...(payload.user === '@me' && { username: state.auth.user.username }),
      },
    });

    // dispatch('eventToEventStore', {
    //   verb: 'MOST_SEARCH_FILTER',
    //   value: {
    //     title: response.data.count[0].value,
    //     word: response.data.count[0].word,
    //   },
    // });
    return response.data.count;
  },

  async pieChartDump({ commit, state }) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/count`, {
      params: {
        username: state.auth.user.username,
      },
    });

    const pieDump = response.data;
    const pieData = pieDump.verbs.map((item) => ({
      type: item.verb,
      value: parseFloat(((item.count / pieDump.count) * 100).toFixed(2)),
    }));
    commit('SET_PIE_DATA', pieData);
  },

  async setRouteDetail({ commit }, routeDetail) {
    commit('SET_ROUTE_INFO', routeDetail);
  },

  async eventsDumpFetch({ commit, dispatch }, payload) {
    const loader = await dispatch('startLoader', {
      event: 'Pulling TJ Event log',
      message: 'All activities',
    });

    const eventsRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events`, {
      params: {
        ...(payload.username.length > 0 && { username: payload.username }),
        ...(payload.verb.length > 0 && { verb: payload.verb }),
        pageNo: payload.pageNo,
      },
    });

    commit('SET_EVENTS_RAW_LOG', eventsRaw);

    dispatch('endLoader', loader);
  },

  async fetchEvents({ commit, state }, username) {
    try {
      let params = {};
      if (username) {
        params.username = username;
      } else if (username === '') {
        params = {};
      } else {
        params.username = state.auth.user.username;
      }

      const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/allevents`, {
        params,
      });

      const eventsByYear = response.data;
      const events = Object.values(eventsByYear).reduce((acc, yearEvents) => acc.concat(yearEvents), []);
      commit('SET_GRAPH_EVENTS', events);
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  },

  async eventsBuckedFetch({ commit, dispatch }) {
    const loader = await dispatch('startLoader', {
      event: 'Pulling TJ Events Sky View',
      message: 'All activities',
    });

    const eventsRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/eventsbucket`);

    commit('SET_EVENTS_BUCKET_LOG', eventsRaw);

    dispatch('endLoader', loader);
  },

  async eventsVerbFetch({ commit, dispatch }, verb) {
    const loader = await dispatch('startLoader', {
      event: `Pulling TJ ${verb}-Event logs `,
      message: verb,
    });

    const eventsRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/verb/${verb}`);

    commit('SET_EVENTS_VERB', eventsRaw);

    dispatch('endLoader', loader);
  },

  async notesFetch({ commit, state }, payload) {
    if (payload.type === 'next') {
      const eventsRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/notes`, {
        params: {
          pageNo: payload.pageNo,
          ...(payload.user === '@me' && { username: state.auth.user.username }),
        },
      });

      commit('SET_NOTES', eventsRaw);
    } else if (payload.type === 'search') {
      const eventsRaw = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/notes`, {
        params: {
          pageNo: payload.pageNo,
          searchWord: payload.searchWord,
        },
      });

      commit('SET_NOTES', eventsRaw);
    }
  },

  async deleteNoteFromLibrary({ commit }, id) {
    commit('DELETE_NOTE_FROM_NOTES_LIBRARY', id);
  },

  async deleteNote({ dispatch }, note) {
    console.log('notes ', note.value.noteTitle);
    await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/notes/${note._id}`);
    const values = {
      verb: 'DELETE_NOTE',
    };
    dispatch('actionFeedback', values);
    await dispatch('eventToEventStore', {
      verb: 'DELETE_NOTE',
      value: {
        title: note.value.noteTitle,
        id: note._id,
      },
    });

    dispatch('deleteNoteFromLibrary', note._id);

    return note._id;
    // commit('DELETE_NOTE', deleteNote);
    // dispatch('endLoader', loader);
    // dispatch('eventsVerbFetch', note.verb);
  },

  startLoader({ commit }, payload) {
    const id = uuidv4();
    commit('START_LOADER', {
      id,
      payload,
    });
    return id;
  },

  endLoader({ commit }, id) {
    commit('END_LOADER', id);
  },

  async saveStateToSnaphot({ state }, recordingData) {
    const { user } = state.auth;
    const { mutationSeries } = recordingData;
    const { startingState } = recordingData;

    const saved = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/states`, {
      actor: user,
      state: startingState,
      mutationSeries,
    });
    router.push({
      name: 'Thought',
      params: { id: saved.data.id },
    });
  },

  async saveCurrentGraph({ dispatch, commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_AXIOS_URI}/g6graph`, {
        actor: state.auth.user,
        g6_data: {
          graph_name: payload.graph_name,
          gdata: payload.gdata,
          graph_type: payload.graph_type,
        },
        ...(payload.graph_dimension && { graph_dimension: payload.graph_dimension }),
        access: payload.graph_access,
        metadata: {
          anchor: state.gmodule.anchor,
        },
        tags: payload.graph_tags,
        ...((payload.graph_access === 'custom') && {
          sharedAccess: payload.sharedAccess,
        }),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }).then((saved) => {
        const values = {
          verb: 'SET_SAVED_GRAPH',
        };
        dispatch('actionFeedback', values);
        commit('SET_SAVED_GRAPH', saved.data);
        if (saved.status === 200) {
          dispatch('fetchAllGraph', {
            user: '@all',
            type: 'page',
            pageNo: 0,
          });
          resolve(saved.data);
        }
        resolve(false);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /**
   * This is for saved graph Library changing layout
   * view: 'grid' | 'list'
  */
  toggleGraphLibraryView({ commit }, view) {
    commit('TOGGLE_GRAPH_LIBRARY_VIEW', view);
  },

  async addNewSavedGraph({ commit }, data) {
    commit('ADD_NEW_SAVED_GRAPH', data);
  },

  updateGraphLibrary({ commit }, data) {
    commit('UPDATE_GRAPH_LIBRARY', data);
  },

  updateGraphLibraryProperty({ commit }, data) {
    commit('UPDATE_GRAPH_LIBRARY_PROPERTY', data);
  },

  deleteItemFromGraphLibrary({ commit }, data) {
    commit('FILTER_GRAPH_LIBRARY', data);
  },

  async FetchVisitedByUser({ state }) {
    const linkVisited = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/verb`, {
      params: {
        verb: 'VISITED',
        username: state.auth.user.username,
      },
    });

    return linkVisited.data;
  },

  async getSavedTjState({ commit, dispatch }, id) {
    const savedState = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/states/${id}`);
    commit('SET_SAVED_TJ_STATE', savedState.data.state);

    if (savedState.data.mutationSeries && savedState.data.mutationSeries.length > 0) {
      dispatch('setMutationSeries', savedState.data.mutationSeries);
    }
    // dispatch('replayMutationSeries', savedState.data.mutationSeries);
  },
  setCurrentBook({ commit, dispatch }, book) {
    commit('SET_CURRENT_BOOK', book);
    const fileNameWithoutExtension = book.replace(/\.md$/, '');
    const currentDate = new Date().getTime();
    const action = {
      book: fileNameWithoutExtension,
      timeStamp: {
        machineReadable: currentDate,
        humanReadable: moment(currentDate).format('MMMM Do YYYY, h:mm:ss a'),
      },
    };

    dispatch('eventToEventStore', {
      verb: 'BOOK_CHECKOUT',
      value: action,
    });
  },

  clearCurrentBook({ commit }) {
    commit('CLEAR_CURRENT_BOOK');
  },

  startThoughtRecord({ state }) {
    state.graph.nodes = [];
    state.graph.links = [];
    state.eventsRaw = [];
    state.eventsVerb = [];
    state.explore.graphData = [];

    const startingState = JSON.parse(JSON.stringify(state));
    return startingState;
  },

  async endThoughtRecord({ dispatch }, recordingData) {
    dispatch('saveStateToSnaphot', recordingData);
  },

  async replayMutationSeries({ commit }, mutationSeries) {
    await asyncForeach(mutationSeries, async (mutationSnap) => {
      commit(mutationSnap.mutation.type, mutationSnap.mutation.payload);
      await sleep(mutationSnap.timeDeltaMillis);
    });
  },

  async setMutationSeries({ commit }, mutationSeries) {
    commit('SET_MUTATIONSERIES', mutationSeries);
  },

  async updateWidgetSettings({ commit, dispatch }, widgetSettings) {
    commit('SET_WIDGET_SETTINGS', widgetSettings);
    await dispatch('upsertSettingsToServer');
  },

  async updateSocialSettings({ commit, dispatch }, socialSettings) {
    commit('SET_SOCIAL_SETTINGS', socialSettings);
    await dispatch('upsertSettingsToServer');
  },

  async upsertSettingsToServer({ state }) {
    if (state.userInfo) {
      const { user } = state.auth;
      const { settings } = state;
      const saved = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/userProfiles/${user.discriminator}`, {
        actor: user,
        settings,
      });

      return saved;
    }
    return null;
  },

  async getUserSettingsFromServer({ commit, state }) {
    if (state.userInfo) {
      const { user } = state.auth;
      const savedSetting = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/userProfiles/${user.discriminator}`);
      commit('SET_WIDGET_SETTINGS', savedSetting.data.settings.widget);
      commit('SET_SOCIAL_SETTINGS', savedSetting.data.settings.widget);
    }
  },

  async fetchThreeDModel({ commit }, searchTerm) {
    const AccessToken = process.env.VUE_APP_SKETCHFAB_TOKEN;
    let { data } = await axios.get(`https://api.sketchfab.com/v3/search?type=models&q=${searchTerm}`, {
      headers: {
        Authorization: `token ${AccessToken}`,
      },
    });
    if (data.results.length === 0) {
      data = {
        results: [{ uid: '26d35013a100480da708852ed8d06651' }],
      };
    }
    commit('SET_THREEDMODELDATA', data);
  },
  async autoCompleteSuggestion({ commit }, searchTerm) {
    const response = await axios.get(`https://api.datamuse.com/sug?&v=enwiki&s=${searchTerm}`);
    const suggestionList = response.data.map((item) => item.word);
    commit('SET_AUTOCOMPLETE_SUGGESTIONS', suggestionList);
  },
  async suggestions({ commit }, chosen) {
    let data = [];
    const Token = process.env.VUE_APP_SUGGESTION_TOKEN;
    const options = {
      method: 'POST',
      url: 'https://jspell-checker.p.rapidapi.com/check',
      headers: {
        'content-type': 'application/json',
        'x-rapidapi-key': `${Token}`,
        'x-rapidapi-host': 'jspell-checker.p.rapidapi.com',
      },
      data: {
        language: 'enUS',
        fieldvalues: chosen,
        config: {
          forceUpperCase: false,
          ignoreIrregularCaps: false,
          ignoreFirstCaps: true,
          ignoreNumbers: true,
          ignoreUpper: false,
          ignoreDouble: false,
          ignoreWordsWithNumbers: true,
        },
      },
    };
    axios
      .request(options)
      .then((response) => {
        data = response.data.elements[0].errors[0].suggestions;

        commit('SET_SUGGESTIONDATA', data);
      })
      .catch((error) => error);
  },

  // async entityCollector({ state }, noteData) {
  //   const data = {
  //     sentence: noteData,
  //     user: state.auth.user,
  //   };
  //   axios.post(`${process.env.VUE_APP_NEOSTG}/entity-collector`, data).then((res) => {});
  // },

  // eslint-disable-next-line no-unused-vars
  async getNote({ commit }, id) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/notes/${id}`);
    commit('singleNote', response.data);
  },

  async addToNote({ dispatch, state }, value) {
    const verb = {
      display: {
        en: 'NOTED',
      },
    };

    const data = {
      verb,
      value,
      actor: state.auth.user,
      dataSource: 'tj-client',
    };
    const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/notes`, data);
    await dispatch('actionFeedback', { ...response.data });
    await dispatch('eventToEventStore', { ...response.data });
    await dispatch('addNoteToNotesLibrary', response.data);
    // socket.emit('add_note', response.data);
    return response.data;
  },

  async generateDiaryInsight({ state, commit }, payload) {
    // get note and update the note with the insight
    const notes = [...state.notes.data];
    const noteIndex = notes.findIndex((note) => note._id === payload.note._id);
    try {
      // start loader for the note
      if (noteIndex !== -1) {
        notes[noteIndex] = { ...notes[noteIndex], progress: 0, status: 'loading' };
        console.log('xvf notes', notes[noteIndex]);
        commit('UPDATE_NOTES_IN_LIBRARY', notes);
      }

      const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/skills/diary/insights`, {
        noteId: payload.note._id,
      });

      if (response.status === 200) {
        if (noteIndex !== -1) {
          notes[noteIndex] = response.data.note;
          notes[noteIndex].progress = 100;
          notes[noteIndex].status = 'completed';
        }
        console.log('xvf', notes);
        commit('UPDATE_NOTES_IN_LIBRARY', notes);
      }

      // return response.data;
    } catch (error) {
      console.error('Error generating diary insight:', error);
      // start loader for the note
      if (noteIndex !== -1) {
        notes[noteIndex] = { ...notes[noteIndex], progress: 0, status: 'error' };
        console.log('xvf notes', notes[noteIndex]);
        commit('UPDATE_NOTES_IN_LIBRARY', notes);
      }
      throw error;
    }
  },

  async addNoteToNotesLibrary({ commit }, data) {
    commit('ADD_NOTE_IN_LIBRARY', data);
  },

  async addNoteAndGraph(context, data) {
    const noteItem = await context.dispatch('addToNote', data);
    if (graphReactor.exploreInstance) {
      const node = await exploreOps.addNoteToGraph(noteItem, context.rootGetters['auth/authUser']);
      return node;
    }
    return data;
  },

  async linkNoteToCurrentActivity({ dispatch, state }, payload) {
    const lastNodeAdded = await dispatch('addNoteAndGraph', payload);
    if (graphReactor.exploreInstance) {
      const edge = {
        source: lastNodeAdded.id,
        target: state.current_selected_entity.word,
        label: 'has reference',
        style: {
          startArrow: true,
        },
      };
      GraphEngine.addEdge('explore', edge);
    }
  },

  updateNodeInfoSection({ commit }, payload) {
    commit('UPDATE_NODE_INFO_SECTION', payload);
  },

  async temporaryDetailUpdate({ commit }, tjModel) {
    commit('SET_PAYLOAD_DETAIL', tjModel);
  },

  // ** DETAIL **
  async updateDetailPayload({ commit, state, dispatch }, payload) {
    const tjModel = state.current_selected_entity?.tjModel || 'No Modal';
    let nodeInstance;
    if (graphReactor.exploreInstance) {
      nodeInstance = graphReactor.exploreInstance.findById(payload);
    }

    // ? If node exist and its type is `wikiLInks` - Fetch description and append it.
    if (nodeInstance && !state.quests.quest_mode) {
      // Explanation: We are not using this block for tjModel because what if user searches. because at that time tjModel is not available
      const nodeModel = nodeInstance.get('model');

      if (nodeModel.tjType === 'wikiLinks' || nodeModel.tjType === 'openai_logic_node') {
        await dispatch('getDescription', nodeModel.label);
        nodeModel.description = state.detail_description;
      }

      graphReactor.exploreInstance.setItemState(
        nodeInstance,
        'focusEd',
        true,
      );

      const finalResult = nodeModel; // ! Deprecated - Will fix this in future PR - Because we won't need this two formDataObj and content.

      commit('SET_PAYLOAD_DETAIL', finalResult);
    } else if (tjModel === 'No Modal') {
      // ? if Node not exist and also current Thought doesn't have any tjModel then make default payload
      await dispatch('_defaultDetail', payload);
    } else {
      // ? if current thought has tjModel property then simply set that.
      commit('SET_PAYLOAD_DETAIL', tjModel);
    }
  },

  // ** DETAIL - child of updateDetailPayload **
  async _defaultDetail({ dispatch, state, commit }, payload) {
    // ? Time when node is not avaialble inside graph
    if (!state.quests.quest_mode) {
      const defaultPayload = {
        id: payload,
        label: payload,
        type: 'circle',
        tjType: 'wikiLinks',
        style: {
          stroke: '#fff',
          lineWidth: 1,
          fill: '#1f1084',
        },
        labelCfg: {
          position: 'top',
          color: '#fff',
          style: {
            opacity: 1,
          },
        },
      };
      await dispatch('getDescription', defaultPayload.label);
      defaultPayload.description = state.detail_description;
      commit('SET_PAYLOAD_DETAIL', defaultPayload);
    }
  },

  async focusCurrentItem({ dispatch, state, commit }, payload) {
    graphReactor.exploreInstance.findAllByState(`${payload.type}`, 'focusEd').forEach((item) => {
      graphReactor.exploreInstance.clearItemStates(item, 'focusEd');
    });
    graphReactor.exploreInstance.focusItem(payload.id);
    graphReactor.exploreInstance.setItemState(payload.id, 'focusEd', true);
    if (payload.state.isActiveDetail === 'Nodes') {
      const ModelData = graphReactor.exploreInstance.findById(payload.id);
      const Model = ModelData.getModel();
      const nodePayload = { content: Model, formDataObj: Model };
      commit('SET_PAYLOAD_DETAIL', nodePayload);
      if (state.gmodule.edit_mode === 'looked_at') {
        const wordObjThought = {
          word: payload.id,
          label: payload.id,
          type: 'string',
          lang: state.locale.currentLocale,
        };

        dispatch('setCurrentThought', {
          thoughtObject: wordObjThought,
          excludeCallList: ['gmodule/exploreGraphFetch'],
        });
      }
      if (state.gmodule.edit_mode === 'explore') {
        // Highway.singleDispatcher('youtubeSearchByKeyword');
        const wordObj = {
          word: payload.id,
          label: payload.id,
          type: 'string',
          lang: state.state.locale.currentLocale,
        };
        // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
        await dispatch('setCurrentThought', { thoughtObject: wordObj });
      }
    }
  },

  async gutenBergBooks({ commit }) {
    const books = await axios.get('https://gutendex.com/books');
    commit('SET_GUT_BOOKS', books.data.results);
  },

  async gutenBergBook({ commit }, id) {
    const book = await axios.get(`https://gutendex.com/books/${id}`);
    commit('SET_GUT_BOOK', book.data);
  },

  async comapreDetailAction({ commit, dispatch }, wordDetail) {
    const response1 = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/snippet`, {
      params: { word: wordDetail.word1, type: 'string', lang: 'en' },
    });
    const response2 = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/snippet`, {
      params: { word: wordDetail.word2, type: 'string', lang: 'en' },
    });
    const word1Result = await response1.data.response;
    const word2Result = await response2.data.response;
    // eslint-disable-next-line
    const commonKeys = Object.keys(word1Result).filter((key) => word2Result.hasOwnProperty(key));
    const commonValues = commonKeys.map((key) => {
      if (key === 'entityLabel' || key === 'entityDescription') {
        return {
          key,
          label: key,
          value1: { value: word1Result[key], type: 'text' },
          value2: { value: word2Result[key], type: 'text' },
        };
      }
      return {
        key,
        label: word1Result[key].label,
        value1: word1Result[key].value,
        value2: word2Result[key].value,
      };
    });
    commit('SET_COMPARE_DETAIL', commonValues);
    dispatch('eventToEventStore', {
      verb: 'COMPARE',
      value: {
        word1: wordDetail.word1,
        word2: wordDetail.word2,
      },
    });
  },

  async wikiSnippet({ commit }, paramsObj) {
    const { data } = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/snippet`, {
      params: paramsObj,
    });

    commit('SET_WIKI_SNIPPET', data.response);
  },

  updateLayoutSettings({ commit }, layoutSettings) {
    commit('SET_LAYOUT_SETTINGS', JSON.parse(JSON.stringify(layoutSettings)));
  },

  changeLocale({ commit }, locale) {
    localStorage.setItem('locale', locale);
    commit('SET_LOCALE', locale);
  },

  async mostSearchedWordList({ commit }) {
    const res = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/query`, {
      query: 'MATCH ()-[]->(a:Word) WITH a.word AS Word, count(()-[]->(a)) AS count RETURN Word, count ORDER BY count DESC',
    });
    // eslint-disable-next-line
    const data = res.data.map((item) => ({ word: item._fields[0], detail: item._fields[1] }));
    commit('SET_MOST_SEARCHED_WORDS', data);
  },

  async compareListAction({ commit }) {
    const res = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/events/verb/COMPARE_LIST`);
    const userData = res.data.filter((item) => item.actor.username === this.state.auth.user.username);
    const comapreList = userData.map((item) => {
      const data = {
        id: item.id,
        word: item.value.word,
      };
      return data;
    });
    commit('SET_COMPARE_LIST', comapreList);
  },

  async wikiGraphAction({ commit, state }, wordObj) {
    const res = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/entity-cluster`, {
      params: {
        word: wordObj.word,
        type: wordObj.type,
        lang: wordObj.lang,
      },
    });

    commit('SET_GRAPH_EXPANSION', res.data);
    commit('setGraph', {
      nodes: state.g6_graph_data.nodes.map((item) => ({
        ...item,
      })),
      links: state.g6_graph_data.edges.map((item) => ({
        ...item,
      })),
    });
  },

  // eslint-disable-next-line no-unused-vars
  async uploadToAWS({ commit, dispatch, state }, blobData) {
    // upload the screen recording to AWS S3
    const fd = new FormData();
    fd.append('upl', blobData, 'blobby.mp4');
    const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/scr/upload`, fd);
    const uploadObj = await response.data;
    const url = uploadObj.Location;

    // // upload the caption file related to the recording to the server
    // const captionResponse = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/scr/uploadvtt`, {
    //   sentences: state.screenRecordCaption,
    //   actor: state.auth.user,
    //   timeStamp: new Date().toISOString(),
    // });

    // const captionUrl = captionResponse.data.Location;

    const screenRecordState = {
      url,
      // captionUrl,
    };
    commit('SET_SCREEN_RECORD_STATE', screenRecordState);

    const value = {
      note: url,
      noteUrl: url,
      // captionUrl: captionUrl || '',
      type: 'recording',
      timestamp: moment().format(),
    };

    const verb = 'NOTED';
    const actor = state.auth.user;

    const data = {
      actor,
      verb,
      value,
      dataSource: 'tj-client',
    };
    dispatch('addToNote', value);

    // empty the screen record caption after adding to note
    dispatch('SET_SCREEN_RECORD_CAPTION', []);

    // return a promise for synchronous use
    return new Promise((resolve) => {
      resolve(data);
    });
  },

  setUploadingG6Data({ commit }) {
    commit('SET_UPLOADING_G6_DATA');
  },

  setQuestUploadingStatus({ commit }) {
    commit('SET_QUEST_UPLOADING');
  },

  screenRecordCaption({ commit }, data) {
    commit('SET_SCREEN_RECORD_CAPTION', data);
    return new Promise((resolve) => {
      resolve(data);
    });
  },

  async graphExpansion({ state, commit }, wordObj) {
    const res = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/entity-cluster`, {
      params: {
        word: wordObj.word,
        type: wordObj.type,
        lang: wordObj.lang,
      },
    });

    commit('SET_GRAPH_EXPANSION', res.data);
    commit('setGraph', {
      nodes: state.g6_graph_data.nodes,
      links: state.g6_graph_data.edges,
    });
  },

  async xapiEvent({ dispatch }, event) {
    const firstStatement = event.statement;

    if (firstStatement) {
      /* eslint-disable */

      const object = firstStatement.object;
      object.definition.extensions = '';

      dispatch('eventToEventStore', {
        verb: firstStatement.verb,
        value: {
          object,
        },
      });
    }
  },
  async getLatestAutoMarkdown({ commit, state }) {
    if (state.userInfo) {
      const user = state.auth.user;
      const thoughtMarkdown = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/query/userMarkdown`, {
        params: {
          actor: user,
        },
      });
      commit('FETCH_AUTO_MARKDOWN', thoughtMarkdown.data);
    }
  },

  async twitterDetails({ state }, twitterObj) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/twitter-sentiment`, {
      params: {
        topic: twitterObj.topic,
        count: twitterObj.count,
      },
    });
    state.twitter_data = response.data.twitter;
  },
  toggleCameraStatus({ commit }) {
    commit('TOGGLE_CAMERA_STATUS');
  },

  async updateNoteInLibrary({ commit }, data) {
    commit('UPDATE_NOTE_IN_LIBRARY', data);
  },

  async updateNoteDispatch({ dispatch, getters }, noteObj) {
    const response = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/notes/update/${noteObj.id}`, {
      note: noteObj.note,
      type: noteObj.type,
    });
    await dispatch('eventToEventStore', {
      verb: 'UPDATE_NOTE',
      value: {
        note: noteObj.note.substring(0, 100),
        id: noteObj.id,
      },
    });

    console.log('Temp Cool');
    console.log('updateNoteGoogly', response)

    dispatch('updateNoteInLibrary', response.data);
    // check goal met
    // console.log('goalUpdate', response.data);
    if (response.data.goal) {
      const tasks = response.data.task;
      const goal = getters['goals/fetchGoalWithId'](response.data.goal);
      if (tasks.total === tasks.completed) {
        dispatch('goals/markAsComplete', { goal: { id: response.data.goal, status: true } });
        eventPayload = {
          verb: 'CLOSED_GOAL',
          value: {
            title: goal.goal,
          },
        };
      } else {
        await dispatch('goals/markAsComplete', { goal: { id: response.data.goal, status: false } });
        const eventsPayload = {
          verb: 'REOPENED_GOAL',
          value: {
            title: goal.goal,
          },
        };
        dispatch('eventToEventStore', eventsPayload);
      }

    }

    return response.data;
  },

  toggleQuestModeWithPayload({ dispatch }, questMode) {
    dispatch('quests/setQuestMode', questMode);

    if (questMode) {
      dispatch('activateLayout', {
        layoutKey: '_b',
        componentName: 'DendroGraphWidget',
      });
    } else {
      dispatch('activateLayout', {
        layoutKey: '_b',
        componentName: 'GraphExploreWidget',
      });
    }
  },

  async getListOfAllQuests({ commit, getters }) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/quests`, {
      headers: getters.getUserAccessToken
        ? {
          Authorization: `Bearer ${getters.getUserAccessToken}`,
        }
        : {},
    });
    commit('SET_ALL_QUESTS', response.data);
  },
  async updateQuestWithGraph({ commit, state }, questObj) {
    const response = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/quests/${state.current_quest_id}`, {
      thought_map: questObj,
    });
  },
  async saveAsQuest({ state, dispatch, commit }) {
    let toStoreNav = state.nav_data;
    toStoreNav.edges = state.nav_data.edges.map((el) => ({
      source: el.source,
      target: el.target,
    }));
    const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/quests`, {
      general_information: [
        {
          title: (state.auth.user?.username || 'open') + '_' + new Date().toISOString(),
        },
      ],
      thought_map: toStoreNav,
    });
    const questID = response.data.id;
    dispatch('getListOfAllQuests');
    commit('SET_CURRENT_QUEST', questID);
  },
  async saveToCurrentQuest({ state, dispatch }) {
    let toStoreNav = state.nav_data;
    toStoreNav.edges = state.nav_data.edges.map((el) => ({
      source: el.source,
      target: el.target,
    }));
    dispatch('updateQuestWithGraph', toStoreNav);
  },

  async loadFromCurrentQuest({ commit, state, dispatch }) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/quests/${state.current_quest_id}`);
    dispatch('cleanSelectedEntity');
    commit('SET_NAV_DATA', response.data.thought_map);
    setTimeout(() => {
      dispatch('setFirstNodeAsCurrentFromQuest');
    }, 1000);
  },

  async setFirstNodeAsCurrentFromQuest({ dispatch, getters }) {
    let availableNodes = getters.getAvailableThoughtNodes;
    if (availableNodes && availableNodes.length > 0) {
      let switchToNode = availableNodes[0];
      const wordObj = {
        word: switchToNode.label,
        label: switchToNode.label,
        type: 'string',
        lang: 'en',
      };
      dispatch('setCurrentThought', { thoughtObject: wordObj, isReplay: true });
    }
  },

  setCurrentQuest({ commit, dispatch }, questID) {
    commit('SET_CURRENT_QUEST', questID);
    dispatch('loadFromCurrentQuest');
  },

  async deleteMyQuest({ state, dispatch }, questID) {
    const response = await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/quests/${questID}`);
    const entity = {
      verb: 'DELETED_QUEST',
      value: {
        // eslint-disable-next-line
        questId: response.data._id,
      },
    };
    dispatch('eventToEventStore', entity);
    await dispatch('actionFeedback', entity);
  },

  async fetchUserAnalytics({ state, commit, dispatch }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/events/analytics`, {
      dataSource: 'BoostEd-extension',
      username: payload.users ? payload.users : state.auth.user?.username,
      days: 3,
    });
    const userAnalyze = {
      verb: 'USER_ANALYTICS',
      value: {
        users: payload.users,
      },
    };
    dispatch('eventToEventStore', userAnalyze);
    commit('SET_USER_ANALYTICS', response.data);
  },

  async setCurrentThought({ state, commit, dispatch, getters }, { thoughtObject, isReplay, excludeCallList }) {


    /**
     * {
     *   thoughtObject: {
     *      type: Object,
     *      required: true
     *   },
     *   isReplay: {
     *      type: Boolean,
     *      required: false,
     *      default: false
     *   },
     *   excludeCallList: {
     *      type: Array,
     *      required: false,
     *      default: []]
     *   },
     * }
     */


    // ? Assign default value
    if (!isReplay) isReplay = false;
    if (!excludeCallList) excludeCallList = [];

    if (getters.compareWithCurrentThought(thoughtObject) === true) {
      return;
    }


    // Don't invoke these calls(Dispatcher)

    commit('SET_EXCLUDE_DISPATCHER', excludeCallList);

    switch (thoughtObject.type) {
      case 'string':
        // Example
        // {
        //   "word": "hello",
        //   "label": "hello",
        //   "type": "string",
        //   "lang": "en",
        // }
        dispatch('setCurrentEntity', thoughtObject);
        break;
      case 'entity':
        // Example
        // {
        //   "qid": "Q415",
        //   "label": "hello",
        //   "type": "string",
        //   "lang": "en"
        // }
        dispatch('setCurrentEntity', thoughtObject);
        break;
      case 'note':
        // Example
        // {
        //    noteId: 'uniqueId as external Identifier',
        //    label: 'Custom Label(optional)',
        //    type: 'note',
        //  }

        dispatch('setCurrentNote', thoughtObject);
        // dispatch('loadEntityFromNote', thoughtObject);
        break;
      case 'graph':
        // Example
        // {
        //    graphId: 'uniqueId as external Identifier',
        //    label: 'Custom Label(optional)',
        //    type: 'graph',
        //  }
        await dispatch('gmodule/setCurrentGraph', thoughtObject);

        if (!thoughtObject.label) {
          if (state.gmodule.graph_detail.graphName) {
            thoughtObject.label = state.gmodule.graph_detail.graphName;
          }
        }

        // dispatch('loadEntityFromGraph', thoughtObject);
        break;
      case 'quest':
        // Example
        // {
        //    questId: 'uniqueId as external Identifier',
        //    label: 'Custom Label(optional)',
        //    type: 'quest',
        //  }
        dispatch('setCurrentQuest', thoughtObject.questId);

      default:
        break;
    }

    const lastSelectedThought = state.current_thought;
    // console.log('xvf -lastSelectedThought', lastSelectedThought);
    commit('SET_CURRENT_THOUGHT', thoughtObject);
    const currentThoughtPos = getters.getCurrentThoughtPositionInThoughtMap;
    if (currentThoughtPos !== -1 && state.questMode) {
      // This means Thought is already in the thought map
      dispatch('updateLayoutFromThoughtMap', thoughtObject.label);
    }
    // create nodes and edges fro GOC
    // console.log('xvf -z', thoughtObject, isReplay, excludeCallList, 'condition', !isReplay, state.isThoughtMapRecording);
    if (!isReplay && state.isThoughtMapRecording) {
      thoughtMapUpdationFlow({ state, commit, getters }, lastSelectedThought, { thoughtObject, isReplay, excludeCallList });
    }

  },


  setCurrentEntity({ commit, dispatch }, entityObj, isReplay = false) {
    commit('SET_CURRENT_ENTITY', entityObj);
  },


  async setTextToSpeech({ state, commit }, data) {
    commit('SET_TEXT_TO_SPEECH', data);
  },

  async setDescriptionMode({ state, commit }, data) {
    commit('SET_DESCRIPTION_MODE', data);
  },

  sendUpdateToThoughtMap({ state, commit, getters }) {
    // explicit save to ThoughtMap has been called
    const entityObj = state.current_thought;
    const lastSelectedEntity = state.last_thought;

    thoughtMapUpdationFlow({ state, commit, getters }, lastSelectedEntity, { thoughtObject: entityObj });
  },

  async clearThoughtMap({ commit, dispatch }) {
    commit('CLEAR_THOUGHT_MAP');
    dispatch('cleanSelectedEntity');
    dispatch('eventToEventStore', {
      verb: 'THOUGHTMAP_CLEAR',
      value: {
        currentThought: 'cleared',
      },
    });
  },

  async clearGraphExplore({ commit, dispatch }) {
    commit('CLEAR_GRAPH_EXPLORE');
    dispatch('cleanGraph');
  },

  async replayQuest({ dispatch, state }) {
    let nodesToReplay = state.nav_data.nodes.filter((el) => el.tjtype === 'entity');
    asyncForeach(nodesToReplay, async (node) => {
      const wordObj = {
        word: node.label,
        label: node.label,
        type: 'string',
        lang: 'en',
      };
      // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
      await dispatch('setCurrentThought', { thoughtObject: wordObj });
      await sleep(1000);
    });
  },



  async toggleThoughtMapRecordingState({ state, commit }) {
    // console.log('xvf -thoughtMapRecorder', state.isThoughtMapRecording);
    if (state.isThoughtMapRecording) {
      commit('SET_THOUGHT_MAP_RECORDING_STATE', false);
    } else {
      commit('SET_THOUGHT_MAP_RECORDING_STATE', true);
    }
  },
  async turnOffThoughtMapRecordingState({ state, commit }) {
    commit('SET_THOUGHT_MAP_RECORDING_STATE', false);
  },

  async updateLayoutFromThoughtMap({ commit, state }, entityLabel) {
    let widgets = this.getters.getWidgetsLinkedToEntityFromLabel;
    let current_layout_tabs = state.layouts[state.layouts.view].layout_tabs;
    let current_layout_selection = state.layouts[state.layouts.view].layout_tab_selection;
    widgets.forEach((widget) => {
      let layoutTabTarget = widget.layoutPos;
      let layoutTabIndex = current_layout_tabs[layoutTabTarget].findIndex((el) => el === widget.widget);
      if (layoutTabIndex === -1) {
        current_layout_tabs[layoutTabTarget].push(widget.widget);
        layoutTabIndex = current_layout_tabs[layoutTabTarget].length - 1;
      }
      current_layout_selection[layoutTabTarget] = layoutTabIndex;
    });

    commit('SET_LAYOUT_SETTINGS', { layout: current_layout_tabs, layout_selection: current_layout_selection });
  },

  async cleanSelectedEntity({ commit }) {
    commit('SET_LAST_SELECTED_ENTITY', null);
    commit('SET_CURRENT_ENTITY', null);
  },



  async youtubeSearchByKeyword({ commit, state }, payload) {
    let youtubeSearchObj;
    if (state.gmodule.anchor && state.gmodule.anchor.length > 0) {
      youtubeSearchObj = `${payload} in ${state.gmodule.anchor} `
    } else {
      youtubeSearchObj = payload;
    }
    const youtubeResponse = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/youtube-search`, {
      type: 'search',
      searchObj: youtubeSearchObj,
    });

    commit('SET_YOUTUBE_SEARCH_RESULT', youtubeResponse.data);
  },

  async setCurrentQuestNode({ dispatch, state }, nodeModel) {

    const exploreResponseObj = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt/info`, {
      prompt: nodeModel.label,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      }
    });

    nodeModel.description = exploreResponseObj.data;
    const wordObj = {
      word: nodeModel.label,
      label: nodeModel.label,
      tjModel: nodeModel,
      type: 'string',
      lang: state.locale.currentLocale,
    };

    dispatch('setCurrentThought', {
      thoughtObject: wordObj,
      excludeCallList: ['quests/questGraphAction', 'gmodule/exploreGraphFetch'],
    });
  },

  async youtubeSearchUsingNextPage({ commit, state }) {
    console.log('xvf', 'youtube search value', state.youtube_search_result);
    if (state.youtube_search_result !== null) {
      const youtubeResponse = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/custom/youtube-search`, {
        type: 'nextPage',
        searchObj: state.youtube_search_result.nextPage,
      });

      commit('UPDATE_YOUTUBE_SEARCH_RESULT', youtubeResponse.data);
    } else {
      return true;
    }
  },
  async victoryMarkerHit({ state, dispatch }) {
    dispatch('eventToEventStore', {
      verb: 'HIT_VICTORY_MARKER',
      value: {
        currentThought: state.current_thought,
        lastThought: state.last_thought,
        questId: state.current_quest_id,
      },
    });
  },

  // Setting up global fontSize
  setGlobalFontSize({ state }, fetchObj) {
    const { fetchFromLocal, _fontSize } = fetchObj;
    if (state.auth.status.isLoggedIn) {
      const fontSize = fetchFromLocal ? localStorage.getItem('font-size') || 75 : _fontSize;
      document.getElementsByTagName('html')[0].style['font-size'] = `${fontSize}%`;
      localStorage.setItem('font-size', fontSize);
    } else {
      document.getElementsByTagName('html')[0].style['font-size'] = '75%';
    }
  },

  // clean up User On Logout
  CleanUpUserOnLogout({ state, dispatch }) {
    if (state.uiStates.sidebarToggleStatus) {
      dispatch('toggleSidebar');
    }
    dispatch('setGlobalFontSize', { fetchFromLocal: true });
    // add here...
  },

  // set up settings for user after signup
  setUserOnLogin({ dispatch }) {
    dispatch('setGlobalFontSize', { fetchFromLocal: true });
    // add here...
  },

  // update Graph Configs
  async updateGraphConfig({ commit }, { property, layout, value }) {
    if (!property || value === undefined || !layout) {
      throw new Error('Please Send property, layout and value of the property along with dispatcher');
    }
    commit('SET_GRAPH_PROPERTIES', { property, layout: layout.toLowerCase(), value });
  },

  // Toggle quest mode
  toggleQuestMode({ commit }) {
    // toggle Quest Mode
    commit('TOGGLE_QUEST_MODE');
  },

  // toggle Graph Library
  toggleGraphLibrary({ commit }, val) {
    commit('SET_LIBRARY_MODE_QUEST', val);
  },


  async downloadFileUsingOpenAIFileId(_, fileId) {
    console.log('making call with file id ', fileId)
    setTimeout(() => {
      window.close();
    }, 16000)
    const data = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-file`, { fileId: fileId }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    window.location.href = data.data.file[0].url
  },

  /**
   *
   * This dispatcher is used to transform query and process it throught openai
   *
   *
   * {
   *    text: String // /eddie this is your statement  eddie/
   *    callback: async (query: string) => response:string // this callback take that statement and will morph as they like it and then will return text
   *    splitter: 'eddie' // you can change as you want but if you won't define then it will take default
   * }
   */

  async parseAndQueryFromOpenAI(_, { text, callback, splitter }) {
    let rawInput = text;
    // extracted commands
    if (!splitter) splitter = 'eddie';
    const commands = extractCommands(text, splitter);

    // if commands is greated than
    if (commands.length > 0) {
      //
      const mappedResponse = await Promise.all(
        commands.map(async (command) => {
          let regex1 = new RegExp(`/${splitter}`, 'g');
          let regex2 = new RegExp(`${splitter}/`, 'g');

          const sanitizedQuery = command.replace(regex1, '').replace(regex2, '');
          const result = await callback(sanitizedQuery);
          return [command, result];
        }),
      );
      mappedResponse.forEach(([command, replacement]) => {
        rawInput = rawInput
          .replace(command, replacement);
      });

      return rawInput;
    }
    return null;
  },
  toggleHslState({ commit }, payload) {
    commit('TOGGLE_HSL_STATE', payload);
  },

};

function extractCommands(inputString, splitter) {
  // Regular expression to match /splitter ... splitter/ patterns
  const regex = new RegExp(`/${splitter} .*? ${splitter}/`, 'g');

  // Extract matches
  const matches = inputString.match(regex);
  return matches || 0;
}

// Extra Util functions for Thought Map
function listOfWidgetsInViewNodes(state) {
  let widgetInViewIndex = state.layouts[state.layouts.view].layout_tab_selection;
  let listOfWidgesNodes = [];
  Object.keys(widgetInViewIndex).forEach((layoutTab) => {
    if (!!state.layouts[state.layouts.view].layout_tabs[layoutTab][widgetInViewIndex[layoutTab]]) {
      listOfWidgesNodes.push({
        id: state.current_thought.label + layoutTab + state.layouts[state.layouts.view].layout_tabs[layoutTab][widgetInViewIndex[layoutTab]],
        widget: state.layouts[state.layouts.view].layout_tabs[layoutTab][widgetInViewIndex[layoutTab]],
        label: state.layouts[state.layouts.view].layout_tabs[layoutTab][widgetInViewIndex[layoutTab]],
        tjtype: 'widget',
        layoutPos: layoutTab,
        current_thought: state.current_thought,
      });
    }
  });
  return listOfWidgesNodes;
}

function thoughtMapUpdationFlow({ state, commit, getters }, lastSelectedEntity, { thoughtObject, isReplay, excludeCallList }) {

  // console.log('xvf -lastSelectedThought ', lastSelectedEntity);
  const nodes = getters.getCurrentEntityPositionInThoughtMap === -1 ? [
    {
      id: generateUniqueIdForThoughtObject(thoughtObject),
      label: thoughtObject.label,
      type: 'modelRect',
      tjtype: 'entity',
      thoughtvalue: { thoughtObject, isReplay, excludeCallList },
      thoughttype: thoughtObject.type,
      size: [150, 50],
      style: {
        fill: state.quests.quest_mode ? '#94288B' : '#010148',
        stroke: '#010148',
      },
      stateIcon: {
        show: false,
      },
    },
    ...listOfWidgetsInViewNodes(state),
  ] : [...listOfWidgetsInViewNodes(state)];
  const edges = [
    ...listOfWidgetsInViewNodes(state).map((node) => {
      return {
        source: generateUniqueIdForThoughtObject(thoughtObject),
        target: node.id
      }
    }),
  ];

  if (!!lastSelectedEntity && state.nav_data.nodes && state.nav_data.nodes.length > 0) {
    edges.push({
      source: generateUniqueIdForThoughtObject(lastSelectedEntity),
      target: generateUniqueIdForThoughtObject(thoughtObject),
    });
  }

  commit('UPDATE_NAV_GRAPH', { nodes, edges });
  commit('SET_LAST_THOUGHT', { ...thoughtObject });

};

function generateUniqueIdForThoughtObject(thoughtObj) {
  return thoughtObj.type + thoughtObj.label;
}



